import React from "react";
import CaseLayout from "../[id]"
import { graphql } from "gatsby";

const YVLayout = ({data,id}) => {
    return <CaseLayout data={data} id={id} yv={true} />
}
export default YVLayout

export const query = graphql`
  query PalauteYVQuery {
    site {
      siteMetadata {
        siteUrl
        serverUrl
        title
        description
      }
    }
  }
`